import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Media = (props) => {
  
  return (
    <Layout>
      <SEO title="Media" />
        <br></br>
        <br></br>
        <div className="container">
         <div className="iframe-container"> 
          <iframe src="https://www.youtube.com/embed/G2zM8OTdEGw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <br></br>

    </Layout>
  );
};

export const query = graphql`
  query MediaQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/media/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default Media;
